import React from "react";
import { Line } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { ArrowDown, ArrowUp } from "ui";
import { useTheme } from "ui/ThemeProvider";

import { generateGradient } from "../utils/chartHelpers";
import { Caption, H2 } from ".";

const Value = styled.div<{ direction: "up" | "down" }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  align-items: baseline;
  margin-bottom: 32px;
  width: min-content;

  color: ${({ theme, direction }) => (direction === "up" ? theme.palette.mint.main : theme.palette.red.main)};
  div {
    width: 32px;
    height: 32px;
    display: grid;
    place-items: center;
    background-color: ${({ theme, direction }) =>
      (direction === "up" ? theme.palette.mint.main : theme.palette.red.main) + theme.opacity.dark};
    border-radius: 32px;
  }
  svg {
    path,
    g {
      stroke: currentColor;
    }
  }
`;

type Props = {
  caption: string;
  arrowDirection: "up" | "down";
  value: string;
};

const SteppedLineChart: React.FC<Props> = ({ caption, arrowDirection, value }) => {
  const theme = useTheme();
  const [aspectRatio, setAspectRatio] = React.useState(536 / 120);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile as string });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet as string });

  React.useEffect(() => {
    if (isMobile) {
      setAspectRatio(272 / 120);
    } else if (isTablet) {
      setAspectRatio(680 / 120);
    } else {
      setAspectRatio(536 / 120);
    }
  }, [isMobile, isTablet]);
  return (
    <div>
      <Caption>{caption}</Caption>
      <Value direction={arrowDirection}>
        <H2>{value}%</H2>
        <div>{arrowDirection === "up" ? <ArrowUp /> : <ArrowDown />}</div>
      </Value>
      <Line
        className="chart"
        data={{
          labels: ["January", "February", "March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "Filled",
              backgroundColor: (context) => generateGradient(context, arrowDirection, theme),
              borderColor: arrowDirection === "up" ? theme.palette.mint.main : theme.palette.red.main,
              data: [40, 23, 12, 73, 56, 32, 12],
              fill: true,
              stepped: "before",
              pointRadius: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          aspectRatio,
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                color: theme.palette.white.main,
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
              },
            },
            y: {
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: { display: false },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default SteppedLineChart;
