import { NextPage } from "next";
import { useEffect } from "react";

import { Page } from "@/components/Page";
import { DashboardPageTemplate } from "@/components/PageTemplate";
import { useModal } from "@/hooks/useModal";
import { SocialProofs } from "@/widgets/home/SocialProofs";
import { Tracker } from "@/widgets/home/Tracker";
import { Vesting } from "@/widgets/home/Vesting";

const DashboardPage: NextPage = () => {
  const modal = useModal();

  useEffect(modal.closeModal, []);

  return (
    <Page title="Dashboard" description="Dashboard">
      <DashboardPageTemplate>
        <Tracker />
        <SocialProofs />
        <Vesting />
      </DashboardPageTemplate>
    </Page>
  );
};

export default DashboardPage;
