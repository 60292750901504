import styled from "styled-components";
import { H2 } from "ui/components/Typography/Heading";
import Body from "ui/components/Typography/Text/Body/styles";

export const Title = styled(H2)`
  margin-bottom: 40px;
  font-weight: 700;
`;

export const Thead = styled.thead`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.white.main + theme.opacity.half};
  border-bottom: 1px solid ${({ theme }) => theme.palette.white.main + theme.opacity.dark};
  th {
    font-weight: 500;
    padding: 32px 0;
  }
`;

export const Tbody = styled.tbody`
  ${Body}
  td {
    border-bottom: 1px solid ${({ theme }) => theme.palette.white.main + theme.opacity.dark};
    padding: 32px 0;
  }
`;
