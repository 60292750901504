import React, { useState } from "react";

import { ChartWrapper, Header, SegmentedControl, Title, Wrapper } from "./components";
import BalanceChart from "./components/BalanceChart";
import LineChart from "./components/LineChart";

const Tracker: React.FC = () => {
  const [chartToShow, setChartToShow] = useState<"balance" | "premiums">("balance");
  return (
    <Wrapper>
      <Header>
        <Title>Tracker</Title>
        <SegmentedControl
          tint="blue"
          options={[
            { label: "Your balance", value: "balance" },
            { label: "Total premiums", value: "premiums" },
          ]}
          name={"filter"}
          value={chartToShow}
          onChange={(e) => setChartToShow(e.target.value as "balance" | "premiums")}
        ></SegmentedControl>
      </Header>
      <ChartWrapper>{chartToShow === "balance" ? <BalanceChart /> : <LineChart />}</ChartWrapper>
    </Wrapper>
  );
};

export default Tracker;
