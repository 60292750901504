import React from "react";
import { Line } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useTheme } from "ui/ThemeProvider";

import { generateGradient } from "../utils/chartHelpers";
import { Caption, H2 } from ".";

const Wrapper = styled.div`
  & > div,
  .chart {
    max-width: 100%;
  }
`;

const LineChart = () => {
  const theme = useTheme();
  const [aspectRatio, setAspectRatio] = React.useState(1104 / 392);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile as string });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet as string });

  React.useEffect(() => {
    if (isMobile) {
      setAspectRatio(272 / 338);
    } else if (isTablet) {
      setAspectRatio(680 / 392);
    } else {
      setAspectRatio(1104 / 392);
    }
  }, [isMobile, isTablet]);

  return (
    <Wrapper>
      <Caption>Premiums Originated</Caption>
      <H2>$ 100 000.00</H2>
      <Line
        className="chart"
        data={{
          labels: ["January", "February", "March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "Filled",
              backgroundColor: (context) => generateGradient(context, "up", theme),
              borderColor: theme.palette.mint.main,
              data: [12, 12, 23, 32, 40, 56, 73],
              fill: true,
              pointRadius: 0,
            },
          ],
        }}
        options={{
          responsive: true,
          aspectRatio,
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: { color: theme.palette.white.main },
            },
            y: {
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: { display: false },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      ></Line>
    </Wrapper>
  );
};

export default LineChart;
