import styled from "styled-components";
import { SegmentedControl as _SegmentedControl } from "ui";
import { H1, H2 as _H2 } from "ui/components/Typography/Heading";
import { Caption as _Caption } from "ui/components/Typography/Text";

export const Wrapper = styled.div`
  margin-bottom: 48px;
`;

export const Header = styled.header`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-auto-flow: row;
    align-items: start;
    grid-gap: 16px;
    margin-bottom: 24px;
  }
`;

export const Title = styled(H1)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const H2 = styled(_H2)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
`;

export const SegmentedControl = styled(_SegmentedControl)`
  min-width: 320px;
`;

export const ChartWrapper = styled.div`
  max-width: 100%;
  padding: 24px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.palette.black.tint};
`;

export const Caption = styled(_Caption)`
  color: ${({ theme }) => theme.palette.white.main + theme.opacity.half};
  margin-bottom: 8px;
`;
