import styled from "styled-components";
import { H3 as _H3 } from "ui/components/Typography/Heading";
import { Caption as _Caption } from "ui/components/Typography/Text";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.black.tint};
  border-radius: 32px;
  padding: 32px 48px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px;
  }
`;

export const Caption = styled(_Caption)`
  color: ${({ theme }) => theme.palette.white.main + theme.opacity.half};
  margin-bottom: 8px;
`;

export const H3 = styled(_H3)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
`;
