import { Chart, Color, ScriptableContext } from "chart.js";
import { CoverTheme } from "ui";

export function generateGradient(
  context: ScriptableContext<"line">,
  arrowDirection: "up" | "down",
  theme: CoverTheme,
): Color | undefined {
  const chart = context.chart;

  if (!chart.chartArea) return;

  return getGradient(chart, theme, arrowDirection);
}

function getGradient({ ctx, chartArea }: Chart, theme: CoverTheme, direction: "up" | "down" = "up") {
  const color = direction === "up" ? theme.palette.mint.main : theme.palette.red.main;
  const { height } = chartArea;

  const gradient = ctx.createLinearGradient(0, 0, 0, height);
  gradient.addColorStop(0, color + theme.opacity.half);
  gradient.addColorStop(1, color + theme.opacity.none);
  return gradient;
}
