import React from "react";
import styled from "styled-components";
import { ExternalLink, MoreVertical } from "ui";

import { Tbody, Thead } from ".";

const Wrapper = styled.table`
  text-align: left;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    min-width: 266.5px;
  }

  th:last-child,
  td:last-child {
    min-width: 134px;
  }
`;

const Table = () => {
  return (
    <Wrapper>
      <Thead>
        <tr>
          <th>Amount</th>
          <th>Date</th>
          <th>Vesting period</th>
          <th></th>
        </tr>
      </Thead>
      <Tbody>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <tr key={index}>
              <td>10 000 USDC</td>
              <td>Today</td>
              <td>180 days</td>
              <td>
                <ExternalLink /> <MoreVertical />
              </td>
            </tr>
          ))}
      </Tbody>
    </Wrapper>
  );
};

export default Table;
