import React from "react";
import styled from "styled-components";

import { Title } from "./components";
import Table from "./components/Table";

const Wrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 120px;
`;

const Vesting = () => {
  return (
    <>
      <Title>Vesting history</Title>
      <Wrapper>
        <Table />
      </Wrapper>
    </>
  );
};

export default Vesting;
