import React from "react";
import styled from "styled-components";

import { formatCurrency } from "@/utils/format";

import SocialProof from "./components/SocialProof";

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); */
  grid-auto-flow: column;
  grid-gap: 48px;
  margin-bottom: 48px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-auto-flow: row;
    grid-gap: 20px;
  }
`;

const proofs = [
  {
    title: "Premiums Originated",
    value: formatCurrency(100_000),
  },
  {
    title: "Earned Programs",
    value: formatCurrency(100_000),
  },
  {
    title: "Total Programs",
    value: "100",
  },
];

const SocialProofs = () => {
  return (
    <Wrapper>
      {proofs.map((proof, index) => (
        <SocialProof key={index} {...proof} />
      ))}
    </Wrapper>
  );
};

export default SocialProofs;
