import React from "react";
import styled from "styled-components";

import { Caption, H2 } from ".";
import BarChart from "./BarChart";
import SteppedLineChart from "./SteppedLineChart";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
  grid-gap: 32px;

  & > div,
  .chart {
    max-width: 100%;
  }

  & > div:nth-child(2) {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }
`;

const BalanceChart = () => {
  return (
    <Wrapper>
      <div>
        <Caption>Total investments</Caption>
        <H2>$ 60 500.00</H2>
        <BarChart />
      </div>
      <div>
        <SteppedLineChart caption="total APR" value="35.5" arrowDirection="down" />
        <SteppedLineChart caption="APY" value="0.85" arrowDirection="up" />
      </div>
    </Wrapper>
  );
};

export default BalanceChart;
