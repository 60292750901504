import React from "react";

import { Caption, H3, Wrapper } from ".";

type Props = {
  title: string;
  value: string;
};

const SocialProof: React.FC<Props> = ({ title, value }) => {
  return (
    <Wrapper>
      <Caption>{title}</Caption>
      <H3>{value}</H3>
    </Wrapper>
  );
};

export default SocialProof;
