import Chart from "chart.js/auto";
import ChatDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "ui/ThemeProvider";

import { formatCurrency } from "../../../../utils/format";

Chart.register();

const BarChart: React.FC = () => {
  const theme = useTheme();
  const [aspectRatio, setAspectRatio] = React.useState(520 / 392);
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile as string });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet as string });

  React.useEffect(() => {
    if (isMobile) {
      setAspectRatio(272 / 312);
    } else if (isTablet) {
      setAspectRatio(680 / 312);
    } else {
      setAspectRatio(520 / 392);
    }
  }, [isMobile, isTablet]);

  return (
    <Bar
      className="chart"
      data={{
        labels: [" "],
        datasets: [
          {
            label: "In progress",
            data: [19700],
            backgroundColor: (context) => {
              const {
                ctx,
                scales: { y },
                data: { datasets },
                chartArea,
              } = context.chart;
              if (!chartArea) return;

              const gradient = ctx.createLinearGradient(
                0,
                y.getPixelForValue(datasets[0].data[0] as number),
                0,
                y.getPixelForValue(0),
              );
              gradient.addColorStop(0, theme.palette.white.main + theme.opacity.half);
              gradient.addColorStop(1, theme.palette.white.main + theme.opacity.none);
              return gradient;
            },
            borderColor: theme.palette.white.main,
            borderWidth: { top: 2, right: 0, bottom: 0, left: 0 },
            datalabels: {
              color: theme.palette.white.main,
              font: [{ family: theme.fonts.secondary, weight: 700, size: 24 }],
              formatter: formatCurrency,
            },
            barPercentage: 1,
            categoryPercentage: 1,
          },
          {
            label: "Mature",
            data: [40800],
            backgroundColor: (context) => {
              const {
                scales: { y },
                ctx,
                data: { datasets },
                chartArea,
              } = context.chart;
              if (!chartArea) return;
              const gradient = ctx.createLinearGradient(0, 0, 0, y.getPixelForValue(datasets[0].data[0] as number));
              gradient.addColorStop(0, theme.palette.blue.main + theme.opacity.half);
              gradient.addColorStop(1, theme.palette.blue.main + theme.opacity.none);
              return gradient;
            },
            borderWidth: { top: 2, right: 0, bottom: 0, left: 0 },
            borderColor: theme.palette.blue.main,
            datalabels: {
              color: theme.palette.white.main,
              font: [{ family: theme.fonts.secondary, weight: 700, size: 24 }],
              formatter: formatCurrency,
            },
            barPercentage: 1,
            categoryPercentage: 1,
          },
        ],
      }}
      options={{
        responsive: true,
        aspectRatio,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: { display: false },
          },
        },
        plugins: {
          legend: {
            reverse: true,
            position: "bottom",
            align: "start",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              color: theme.palette.white.main,
              boxWidth: 6,
              boxHeight: 6,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      plugins={[ChatDataLabels]}
    ></Bar>
  );
};

export default BarChart;
